import React, { useState, useEffect } from 'react';
import { Flex, Box, Text, Button, Heading } from '@chakra-ui/core';

import { APP_CONTENT_WIDE_WIDTH } from '../styles/sizes';
import { cloudfrontBaseUrl } from '../lib/consts';
import { toRoundedDollars } from '../lib/toDollars';
import canUseDOM from '@lib/canUseDOM';
import {
  trackReferral,
  referralSignupUrl,
  referralApiUrl,
  storeReferralToken,
} from '../lib/referral/utils';

import ContentContainer from '../components/ContentContainer';
import Footer from '../layouts/Footer';
import CardFeatures from '../components/jointAccount/cardFeatures';
import BankingForTwo from '../components/jointAccount/bankingForTwo';
import Testimonial from '../components/jointAccount/testimonial';
import AffiliateNavigation from '../Navigation/AffiliateNavigation';
import ImageRotator from '../components/referral/ImageRotator';

import { colors } from '@theme';

const GoodLife = ({ location }) => {
  const affiliateImage = `${cloudfrontBaseUrl}/affiliates/goodlife/goodlife.png`;

  const slug = 'goodlife';

  const [loaded, setLoaded] = useState(false);
  const referralAmount = 5000;

  const signUpUrl = referralSignupUrl();
  const apiUrl = referralApiUrl();

  const track = async () => {
    const referralData = await trackReferral({ slug });
    storeReferralToken(referralData.referralToken);
    setLoaded(true);
  };

  useEffect(() => {
    track();
  }, [apiUrl]);

  return (
    <Box>
      <AffiliateNavigation signupUrl={signUpUrl} referralAmount={referralAmount} />
      <Box background={colors.veryLightGreen}>
        <Flex className="main" pt={['2rem', '8rem']} direction="column">
          <ContentContainer>
            <Flex justify="between" direction={['column', 'row']} mb={['2rem', '5rem']}>
              <Box>
                <Flex align={['center', 'start']} direction="column">
                  <img style={{ height: '124px' }} src={affiliateImage} />
                  <Text
                    fontWeight={700}
                    fontSize={['24px', '36px']}
                    my={['1rem', '1rem']}
                    lineHeight={1.25}
                    sx={
                      loaded
                        ? { opacity: 1, transition: 'all 250ms linear' }
                        : { opacity: 0, transition: 'all 250ms linear 2s' }
                    }
                  >
                    We want to give you {toRoundedDollars(referralAmount)}
                  </Text>

                  <Text
                    textAlign={['center', 'left']}
                    fontSize={['16px', '18px']}
                    my={['1rem', '1rem']}
                    width={['19rem', '30rem']}
                    sx={
                      loaded
                        ? { opacity: 1, transition: 'all 250ms linear' }
                        : { opacity: 0, transition: 'all 250ms linear 2s' }
                    }
                  >
                    <strong>Want more of that Good Life?</strong>
                    <br />
                    Open a Zeta Joint Account with no monthly fees and a ton of smart
                    automation to help you master your shared finances. For a limited time
                    - as a Good Life perk, you'll get an extra{' '}
                    {toRoundedDollars(referralAmount)} when you make a qualifying direct
                    deposit.
                  </Text>

                  <Button
                    rounded={10}
                    onClick={() => {
                      if (canUseDOM) {
                        window.location.href = signUpUrl;
                      }
                    }}
                    height="40px"
                    width="10rem"
                    px={[0, 10]}
                    mt={['2rem', '1rem']}
                    sx={
                      loaded
                        ? { opacity: 1, transition: 'all 250ms linear' }
                        : { opacity: 0, transition: 'all 250ms linear 2s' }
                    }
                    style={{
                      backgroundColor: colors.lightGreen,
                      color: colors.iceberg,
                    }}
                    _hover={{
                      backgroundColor: `${colors.darkGreenHover} !important`,
                      textColor: `${colors.iceberg} !important`,
                    }}
                  >
                    <strong>Get Started</strong>
                  </Button>

                  <Text
                    mt={['1rem', 0]}
                    pt={2}
                    fontSize="14px"
                    textAlign="center"
                    fontStyle="italic"
                    fontWeight={400}
                    color={colors.boulder}
                  >
                    See{' '}
                    <a
                      style={{ color: colors.greenPea }}
                      target="_blank"
                      href="/goodlife-terms-and-conditions"
                    >
                      Terms and Conditions
                    </a>
                    .
                  </Text>
                </Flex>
              </Box>
              <Flex
                justify={['center', 'left']}
                mt={['2rem', '2rem']}
                pl={[0, '4rem']}
                lineHeight="18px"
              >
                <ImageRotator />
              </Flex>
            </Flex>
          </ContentContainer>
        </Flex>
      </Box>
      <Flex justify="center" pt={[16]}>
        <ContentContainer>
          <Flex justify="center" textAlign="center">
            <Heading fontSize={['2xl', '4xl']} as="h1">
              What's special about Zeta Joint Cards
            </Heading>
          </Flex>
          <BankingForTwo />
        </ContentContainer>
      </Flex>
      <Flex bg={colors.iceberg} justify="center" className="jc-features" mt={[0, 24]}>
        <ContentContainer maxWidth={APP_CONTENT_WIDE_WIDTH}>
          <CardFeatures subtitle="Your Zeta Joint Account also includes these features:" />
        </ContentContainer>
      </Flex>
      <Flex bg={colors.lightGray} className="testimonial">
        <ContentContainer maxWidth={APP_CONTENT_WIDE_WIDTH}>
          <Testimonial />
        </ContentContainer>
      </Flex>
      <Footer noSubscribe />
    </Box>
  );
};

export default GoodLife;
