import React from 'react';
import Lottie from 'react-lottie';
import { Flex, Box, Text, Image } from '@chakra-ui/core';

import { colors } from '@theme';
import { Tag } from '@zeta';
import { H2, H4 } from '@typography';
import { DesktopOnly, MobileOnly } from '../../layouts/Footer';
import { cloudfrontBaseUrl } from '../../lib/consts';

import brAnim from '../../lotties/bill-reserve-animation.json';
import envAnim from '../../lotties/envelope-system-animation.json';
import goalsAnim from '../../lotties/goals-animation.json';
import memAnim from '../../lotties/members-animation.json';
import msgAnim from '../../lotties/messaging-animation.json';

const BankingForTwo = () => {
  const feature6 = `${cloudfrontBaseUrl}/joint-accounts/features_6.png`;

  return (
    <Flex py={[10, 24]} direction="column">
      <Flex
        justify="center"
        align="center"
        direction="column"
        textAlign="center"
        mb={[0, 16]}
        px={[4, 0]}
      >
        <H2>Our key features</H2>
        <Flex mt="12px" mb={8} justify="center">
          <MobileOnly>
            <H4 center>
              Your Zeta account is designed
              <br />
              to help you master money
              <br />
              together.
            </H4>
          </MobileOnly>

          <DesktopOnly>
            <H4 center>
              Your Zeta account is designed to help you master money together.
            </H4>
          </DesktopOnly>
        </Flex>
      </Flex>

      <Flex
        backgroundColor={[colors.backgroundDos, colors.white]}
        borderRadius={['15px', 0]}
        mx={[4, 0]}
        px={[4, 0]}
        pb={['32px', 0]}
        direction="row"
        justify={['flex-start', 'space-between']}
        wrap={['wrap-reverse', 'no-wrap']}
      >
        <Flex
          width={['100%', '45%']}
          direction="column"
          justify={['left', 'center']}
          textAlign={['left', 'left']}
        >
          <Tag
            borderColor={colors.black}
            width={['136px', '162px']}
            text="envelope system"
          />
          <Text
            lineHeight={['28.8px', '38px']}
            fontWeight={600}
            color={colors.black}
            fontSize={['24px', '32px']}
            pt="22px"
            as="h3"
          >
            Set up your account
            <br />
            in 5 minutes
          </Text>

          <Text
            lineHeight={['24px', '25px']}
            fontSize={['16px', '18px']}
            color={[colors.black, colors.boulder]}
            pt="24px"
          >
            Use our envelope system to seamlessly manage your shared financial life in one
            place. Whether or not you fully combine your finances, Zeta adapts to you so
            you can manage your money your way.
          </Text>
        </Flex>
        <Flex
          aligncontent="center"
          width={['auto', '50%']}
          justify={['center', 'flex-end']}
        >
          <Lottie
            width="100%"
            style={{
              cursor: 'auto',
            }}
            isClickToPauseDisabled
            options={{ loop: true, autoplay: true, animationData: envAnim }}
          />
        </Flex>
      </Flex>

      <Flex
        backgroundColor={[colors.backgroundDos, colors.white]}
        borderRadius={['15px', 0]}
        pb={['32px', 0]}
        direction="row"
        justify={['flex-start', 'space-between']}
        wrap={['wrap', 'no-wrap']}
        mt={[8, 0]}
        mx={[4, 0]}
        px={[4, 0]}
      >
        <Flex width={['100%', '50%']} justify={['center', 'flex-start']}>
          <Lottie
            isClickToPauseDisabled
            style={{
              cursor: 'auto',
            }}
            options={{ loop: true, autoplay: true, animationData: brAnim }}
          />
        </Flex>
        <Flex
          width={['100%', '45%']}
          direction="column"
          justify={['left', 'center']}
          textAlign={['left', 'left']}
        >
          <Tag
            borderColor={colors.black}
            width={['108px', '120px']}
            text="bill reserve"
          />
          <Text
            color={colors.black}
            lineHeight={['28.8px', '38px']}
            fontWeight={600}
            fontSize={['2xl', '32px']}
            pr={[0, 8]}
            pt="22px"
          >
            Organize your bills
          </Text>
          <Text
            lineHeight={['24px', '25px']}
            fontSize={['16px', '18px']}
            color={[colors.black, colors.boulder]}
            pt="24px"
          >
            Never miss a bill payment. Plan for your bills and set aside funds for them.
            We'll even make your bill payments via check, debit card or ACH — all handled
            by us.
          </Text>
        </Flex>
      </Flex>

      <Flex
        backgroundColor={[colors.backgroundDos, colors.white]}
        borderRadius={['15px', 0]}
        pb={['32px', 0]}
        direction="row"
        justify={['flex-start', 'space-between']}
        wrap={['wrap-reverse', 'no-wrap']}
        mt={[8, 0]}
        mx={[4, 0]}
        px={[4, 0]}
      >
        <Flex
          width={['100%', '45%']}
          direction="column"
          justify={['left', 'center']}
          textAlign={['left', 'left']}
        >
          <Tag borderColor={colors.black} width={['66px', '72px']} text="goals" />
          <Text
            lineHeight={['28.8px', '38px']}
            fontWeight={600}
            color={colors.black}
            fontSize={['24px', '32px']}
            pt="22px"
            as="h3"
          >
            Achieve the life you
            <br />
            want, faster
          </Text>
          <Text
            lineHeight={['24px', '25px']}
            fontSize={['16px', '18px']}
            color={[colors.black, colors.boulder]}
            pt="24px"
          >
            With Goals, break down your savings into dedicated envelopes. Use auto-save to
            meet your goals quickly — and earn interest on your entire account, with no
            limits.
          </Text>
        </Flex>

        <Flex width={['100%', '50%']} justify={['center', 'flex-end']}>
          <Lottie
            isClickToPauseDisabled
            style={{
              cursor: 'auto',
            }}
            options={{ loop: true, autoplay: true, animationData: goalsAnim }}
          />
        </Flex>
      </Flex>

      <Flex
        backgroundColor={[colors.backgroundDos, colors.white]}
        borderRadius={['15px', 0]}
        pb={['32px', 0]}
        direction="row"
        justify={['flex-start', 'space-between']}
        wrap={['wrap', 'no-wrap']}
        mt={[8, 0]}
        mx={[4, 0]}
        px={[4, 0]}
      >
        <Flex width={['100%', '50%']} justify={['center', 'flex-start']}>
          <Lottie
            isClickToPauseDisabled
            style={{
              cursor: 'auto',
            }}
            options={{ loop: true, autoplay: true, animationData: msgAnim }}
          />
        </Flex>

        <Flex
          width={['100%', '45%']}
          direction="column"
          justify={['left', 'center']}
          textAlign={['left', 'left']}
        >
          <Tag
            borderColor={colors.black}
            width={['138px', '168px']}
            text="in-app messaging"
          />

          <Text
            lineHeight={['28.8px', '38px']}
            fontWeight={600}
            color={colors.black}
            fontSize={['24px', '32px']}
            pt="22px"
            as="h3"
          >
            Communication
            <br />& support
          </Text>
          <Text
            lineHeight={['24px', '25px']}
            fontSize={['16px', '18px']}
            color={[colors.black, colors.boulder]}
            pt="24px"
          >
            In-app messaging keeps you in sync with your team. Stay informed through
            transaction notifications, spending trackers, and more. You'll also get a
            dedicated Zeta Concierge — a human who's there to help if you need it.
          </Text>
        </Flex>
      </Flex>

      <Flex
        backgroundColor={[colors.backgroundDos, colors.white]}
        borderRadius={['15px', 0]}
        pb={['32px', 0]}
        direction="row"
        justify={['flex-start', 'space-between']}
        wrap={['wrap-reverse', 'no-wrap']}
        mt={[8, 0]}
        mx={[4, 0]}
        px={[4, 0]}
        pt={['48px', 8]}
      >
        <Flex
          width={['100%', '45%']}
          direction="column"
          justify={['left', 'center']}
          textAlign={['left', 'left']}
        >
          <Box mt={['80px', null]}>
            <Tag
              borderColor={colors.black}
              width={['180px', '200px']}
              text="add account members"
            />
          </Box>
          <Text
            lineHeight={['28.8px', '38px']}
            fontWeight={600}
            color={colors.black}
            fontSize={['24px', '32px']}
            pt="22px"
            as="h3"
          >
            An account that
            <br />
            grows with your family
          </Text>
          <Text
            lineHeight={['24px', '25px']}
            fontSize={['16px', '18px']}
            color={[colors.black, colors.boulder]}
            pt="24px"
          >
            You'll be able to add other family members to your account, including more
            account owners. We also launched personal accounts for those of you who need a
            joint and individual account. Coming soon companions with their own card
            limits for nannies, parents, and teens.
          </Text>
        </Flex>
        <Flex width={['100%', '45%']} justify={['center', 'flex-end']}>
          {/* <Lottie
            isClickToPauseDisabled
            style={{
              cursor: 'auto',
            }}
            options={{ loop: true, autoplay: true, animationData: memAnim }}
          /> */}
          <Image
            mt={[null, '82px']}
            src={`${cloudfrontBaseUrl}/joint-accounts/grow-with-family.png`}
            width={['312px', '368px']}
            height={['294px', '359px']}
            alt="nanny-sibling-kids"
          />
        </Flex>
      </Flex>

      <Flex
        backgroundColor={[colors.backgroundDos, colors.white]}
        borderRadius={['15px', 0]}
        pt={['48px', 8]}
        pb={['32px', 0]}
        direction="row"
        justify={['flex-start', 'space-between']}
        wrap={['wrap', 'no-wrap']}
        mt={[8, '140px']}
        mx={[4, 0]}
        px={[4, 0]}
      >
        <Flex width={['100%', '45%']} justify={['center', 'flex-start']}>
          <Image
            width={['100%', '90%']}
            src={feature6}
            maxHeight={'100%'}
            alt="lock-security"
          />
        </Flex>
        <Flex
          width={['100%', '45%']}
          direction="column"
          justify={['left', 'center']}
          textAlign={['left', 'left']}
          mt={[8, 0]}
        >
          <Tag borderColor={colors.black} width={['88px', '98px']} text="security" />

          <Text
            lineHeight={['28.8px', '38px']}
            fontWeight={600}
            color={colors.black}
            fontSize={['24px', '32px']}
            pt="22px"
            as="h3"
          >
            We keep your money
            <br />
            (and data) safe
          </Text>
          <Text
            lineHeight={['24px', '25px']}
            fontSize={['16px', '18px']}
            color={colors.boulder}
            pt="24px"
          >
            The funds in your Zeta account are FDIC insured
            <a href="#zeta-disclosure">
              <sup style={{ color: colors.greenPea }}>1</sup>
            </a>{' '}
            through Piermont Bank, Member FDIC, up to the standard maximum of $250,000 per
            depositor, per institution. Plus, two-factor authentication & data encryption
            keep your data secure. We don't store your external login credentials and will
            never sell your data.
          </Text>
        </Flex>
      </Flex>
    </Flex>
  );
};

export default BankingForTwo;
